<template>
  <div class="class-one">
    <div class="classadmin">
      <div class="classadmin-head">
        设置常用品类
        <div class="head-pos"></div>
      </div>
      <br />
      <br />
      <!-- 可拖拽排序 ↓-->
      <vuedraggable class="wrapper" v-model="options" @end="moveEnd">
        <transition-group>
          <div
            v-for="item in options"
            :key="item.goodsTypeId"
            :class="getclass(item.goodsTypeId)"
            @mousemove="mousemovenow(item.goodsTypeId)"
          >
            <span> {{ item.name }}</span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <el-dropdown
              @command="handleCommand"
              v-show="item.goodsTypeId == value"
            >
              <span class="el-icon-more"> </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">上移</el-dropdown-item>
                <el-dropdown-item command="b">编辑</el-dropdown-item>
                <el-dropdown-item command="c">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </transition-group>
      </vuedraggable>
      <!-- 可拖拽排序 ↑-->
      <!-- <div class="admin-select">
        <el-select v-model="value">
          <el-option
            v-for="item in options"
            :key="item.goodsTypeId"
            :label="item.name"
            :value="item.goodsTypeId"
          >
          </el-option>
        </el-select>
        <div class="class-po">
          <el-dropdown @command="handleCommand">
            <span class="el-icon-more"> </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">默认</el-dropdown-item>
              <el-dropdown-item command="b">编辑</el-dropdown-item>
              <el-dropdown-item command="c">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div> -->
      <div class="button">
        <el-button type="primary" size="mini" @click="addshop()"
          >新增商品品类</el-button
        >
        <el-dialog title="新增商品类目" :visible.sync="dialogFormVisible">
          <div style="margin-left: 50px">
            <el-form :model="form" ref="ruleForm" :rules="rules">
              <el-form-item label="商品类目" prop="name">
                <el-input
                  size="mini"
                  style="width: 280px"
                  maxlength="16"
                  v-model="form.name"
                ></el-input>
              </el-form-item>
              <el-form-item label="特殊资源" prop="resource">
                <el-radio-group v-model="form.resource">
                  <el-radio label="1">日常品</el-radio>
                  <el-radio label="2">生鲜/冷冻</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="addFn()">确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="编辑商品类目" :visible.sync="dialogFormVisible2">
          <div style="margin-left: 50px">
            <el-form :model="form2" ref="ruleForm2" :rules="rules2">
              <el-form-item label="商品类目">
                <el-input
                  size="mini"
                  style="width: 280px"
                  maxlength="16"
                  placeholder="请输入商品类目"
                  v-model="form2.name"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="特殊资源" prop="resource">
                <el-radio-group v-model="form2.resource">
                  <el-radio :label="1">日常品</el-radio>
                  <el-radio :label="2">生鲜/冷冻</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible2 = false">取 消</el-button>
            <el-button type="primary" @click="bjFn()">确 定</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getclasslist,
  AddOrEdit,
  Delete,
  SetDefault,
  SortChange,
} from "../../api/classadmin";
import vuedraggable from "vuedraggable";

export default {
  data() {
    return {
      options: [],
      dialogFormVisible: false, //新增弹窗
      dialogFormVisible2: false, //编辑弹窗
      value: "",
      form: {
        name: "",
        resource: "",
      },
      form2: {
        name: "",
        resource: "",
      },
      rules: {
        name: [{ required: true, message: "请输入商品类目", trigger: "blur" }],
        resource: [
          { required: true, message: "请选择特殊资源", trigger: "change" },
        ],
      },
      rules2: {
        name: [{ required: true, message: "请输入商品类目", trigger: "blur" }],
        resource: [
          { required: true, message: "请选择特殊资源", trigger: "change" },
        ],
      },
    };
  },
  components: { vuedraggable },
  methods: {
    moveEnd() {
      console.log(this.relatedElement);
      console.log(this.draggedElement);
      console.log(this.options);
      var postdata = [];
      //给数据排序
      this.options.forEach((item, index) => {
        postdata.push({
          goodsTypeId: item.goodsTypeId,
          sortIndex: index,
        });
      });
      SortChange(postdata).then((res) => {
        console.log(res);
        if (res.code === 10000) {
          this.$message("成功");
        } else {
          this.$message(res.msg);
        }
      });
      // return (
      //   (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      // );
    },
    mousemovenow(val) {
      this.value = val;
    },
    getclass(val) {
      if (this.value == val) return "item2";
      else return "item";
    },
    //点击新增打开弹窗
    addshop() {
      this.dialogFormVisible = true;
    },
    //新增确认
    addFn() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = {
            name: this.form.name,
            Category: this.form.resource,
          };
          AddOrEdit(data).then((res) => {
            console.log(res);
            if (res.code === 10000) {
              this.getlist();
              this.dialogFormVisible = false;
            } else {
              this.$message(res.msg);
            }
          });
        }
      });
    },
    updated() {
      console.log(this.options);
    },
    //获取下拉列表
    getlist() {
      let dio = "";
      getclasslist()
        .then((res) => {
          console.log(res);
          if (res.code === 10000) {
            // res.data.forEach(item => {
            //     if(item.isDefault==true){
            //     let dio=item
            //     res.data.
            //     }
            // });
            for (var i = 0; i < res.data.length; i++) {
              if (res.data[i].isDefault == true) {
                dio = res.data[i];
                res.data.splice(i, 1);
              }
            }
            res.data.unshift(dio);
            // console.log(dio);
            console.log(res.data);
            (this.options = res.data), (this.value = res.data[0].goodsTypeId);
          } else {
            this.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //操作
    handleCommand(val) {
      if (val === "c") {
        //删除
        let data = {
          id: Number(this.value),
        };
        this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            Delete(data).then((res) => {
              console.log(res);
              if (res.code === 10000) {
                this.getlist();
                this.$message("删除成功");
              }
            });
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else if (val === "b") {
        var valNow = this.options.find((a) => a.goodsTypeId == this.value);
        // if (valNow) this.form2.name = valNow.name;
        if (valNow) {
          this.form2.name = valNow.name;
          this.form2.resource = valNow.category;
          console.log(this.form2.resource, "valNow");
        }
        this.dialogFormVisible2 = true;
      } else if (val === "a") {
        let data = {
          id: Number(this.value),
        };
        SetDefault(data)
          .then((res) => {
            console.log(res);
            if (res.code === 10000) {
              this.$message("设置上移成功");
              this.getlist();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    //编辑确认
    bjFn() {
      this.$refs.ruleForm2.validate((valid) => {
        if (valid) {
          let data = {
            name: this.form2.name,
            goodsTypeId: this.value,
            Category: this.form2.resource,
          };
          AddOrEdit(data).then((res) => {
            console.log(res);
            if (res.code === 10000) {
              this.getlist();
              this.dialogFormVisible2 = false;
            } else {
              this.$message(res.msg);
            }
          });
        }
      });

      // let data = {
      //   name: this.form2.name,
      //   goodsTypeId: this.value,
      // };
      // AddOrEdit(data).then((res) => {
      //   console.log(res);
      //   if (res.code === 10000) {
      //     // this.getlist();
      //     this.dialogFormVisible2 = false;
      //   } else {
      //     this.$message(res.msg);
      //   }
      // });
    },
  },
  created() {
    this.getlist();
  },
};
</script>

<style scoped>
.class-one {
  height: 100%;
  background: white;
}
.classadmin {
  width: 300px;
  height: 200px;
  margin-left: 50px;
  margin-top: 5px;
}
.classadmin-head {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  text-indent: 20px;
  position: relative;
}
.head-pos {
  width: 3px;
  height: 18px;
  background: #0084ff;
  position: absolute;
  left: 0;
  top: 4px;
}
.admin-select {
  width: 100%;
  height: 150px;
  margin-top: 30px;
  position: relative;
}
.class-po {
  position: absolute;
  right: -20px;
  top: 0;
}
.shu {
  width: 1px;
  height: 5px;
  border: 1px dotted #999999;
}
.button {
  margin-top: 80px;
}
/* .wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
} */

.item {
  width: 200px;
  height: 30px;
}
.item2 {
  width: 200px;
  height: 30px;
  background-color: #97c1ce;
}
.item :hover {
  background-color: #97c1ce;
}
</style>
